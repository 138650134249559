.popup-info-content {
  padding: 20px;
  /* font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; */
}

.popup-info-title {
  margin-bottom: 15px !important;
  margin-top: 10px !important;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: #005075;
}

.popup-subtitle {
  font-family: "Cairo";
  margin-bottom: 0;
}

.scrollable {
  overflow-y: auto;
  max-height: calc(80vh - 114px);
}

.scrollable::-webkit-scrollbar {
  width: 10px;
  display: block !important;
}

.scrollable::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable::-webkit-scrollbar-thumb {
  background: #888;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background: #555;
}
