.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.spinner {
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-5 {
  margin-right: 5px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-0 {
  margin-top: 0;
}

.m-10 {
  margin: 10px;
}

.box {
  border: 1px solid #ededed;
  border-radius: 5px;
  padding: 10px;
}

.mw-400 {
  max-width: 400px;
}

.mw-200 {
  max-width: 200px;
}

.mh-400 {
  max-height: 400px;
}

.mw-50p {
  max-width: 50%;
}

.bold {
  font-weight: bold;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.loader-20 {
  max-height: 20px !important;
  max-width: 20px !important;
}

.display-b {
  display: block !important;
}

.ta-r {
  text-align: right;
}

.ta-c {
  text-align: center;
}

.clickable {
  cursor: pointer;
}

.w-80 {
  width: 80% !important;
}

.loading-section {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.m-15 {
  margin: 15px;
}

.p-10 {
  padding: 10px;
}

.general-background {
  background-color: #f1f5f8;
}

.bgc-w {
  background-color: #fff;
}

.c-red {
  color: #d82829 !important;
}

.c-green {
  color: #09822a;
}

.c-label {
  color: #365269;
}

.fs-15 {
  font-size: 15px !important;
}

.word-break {
  word-break: break-all;
}

.display-f {
  display: flex;
}

.notification-banner {
  position: absolute;
  bottom: 20px;
  width: 99%;
  display: flex;
  justify-content: center;
}

.notification-banner>div {
  width: fit-content !important;
  box-shadow: rgb(21 41 53 / 32%);
}