div.form__head {
  height: 0;
  padding: 0;
}

div.form__body {
  margin: 0;
}

.preview-box {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
}

.xml-preview {
  height: 100%;
  width: 100%;
  margin-top: 10px;
  overflow: auto;
}

.xml-preview::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  display: block !important;
}

.xml-preview::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.xml-preview::-webkit-scrollbar-thumb {
  background: #888;
}

.xml-preview::-webkit-scrollbar-thumb:hover {
  background: #555;
}