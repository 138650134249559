.button-error>button {
  color: #D82829 !important;
}

.button-error>button>svg {
  color: #D82829 !important;
}

.back-to-upload {
  display: flex;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #0090D1;
  cursor: pointer;
  margin-top: 20px;
}