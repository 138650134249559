.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #005075;
}

.card-section {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: #005075;
}

.subtitle-section {
  font-family: Roboto;
  font-style: normal;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: .2px;
}

.sw-version {
  font-family: Roboto;
  font-style: normal;
  font-size: 11px;
  letter-spacing: .2px;
  color: #707070;
}
