.upload-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(255, 255, 255, 0.0001) !important;
  border: 1px dashed #0090d1 !important;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  width: 329px !important;
  height: 85px !important;
}

.upload-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */
  letter-spacing: 0.2px;
  color: #0090d1;
}

.upload-btn {
  border: 1px solid #0090d1;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #0090d1;
  padding: 8px 12px;
  cursor: pointer;
}

.upload-container-disabled {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(255, 255, 255, 0.0001) !important;
  border: 1px dashed #979797 !important;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  width: 329px !important;
  height: 85px !important;
}

.upload-text-disabled {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */
  letter-spacing: 0.2px;
  color: #979797;
}

.upload-btn-disabled {
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #979797;
  padding: 8px 12px;
}

.uploaded-filename {
  width: 329px;
  height: 32px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(21, 41, 53, 0.32);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: #152935;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
}

.uploaded-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: min-content;
  padding: 3px 0px;
}

.delete-icon {
  color: #D82829;
}